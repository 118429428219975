import React from "react"
import { Link } from "@StarberryUtils";
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"

const NotFoundPage = (props) => (
  <>
  <HeaderTwo />
    <SEO location={props.location} title="404: Not found" />
    <div className="content">
	    <div className="error-page-content">
		    <h1><span>Ooops!...</span></h1>
		    <h2>The page you’re after seems to be missing.</h2>
		    <p>This is maybe because it has been removed, we’ve changed it’s name or it is temporarily unavailable.</p>
		    <div class="exp-btns">
		    	<Link to="/" class="btn">back to homepage</Link>
		    	<Link to="/contact" class="btn">contact us</Link>
		    </div>
		</div>
	</div>
	<CanHelp />
    <Footer Select_Popular_Search="Static_Common" popularSearch="static"/>
  </>
)

export default NotFoundPage
